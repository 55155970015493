import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link  } from "@mui/material";
import Image from "../image/Image";
// import Evd from "./logo.png";
import ALogo from "./AmartalLogo.png";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src= {EvdLogo}
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // ); 

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: '450px',
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <img src={ALogo} alt="Logo" width='400px'/>
    
    </Box>
  );

  if (disabledLink) {
    return <>{ALogo}</>;
  }

  return (
    <Link
      to="/dashboard/app"
      component={RouterLink}
      sx={{ display: "contents"}}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
