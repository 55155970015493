import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { getNavAdminIdAsync } from "../../../redux/async.api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const { userData } = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();
  const staffId = localStorage.getItem("staffId");

  // console.log(staffId,'staffId');

  // const userDetails =()=>{
  //   dispatch(getNavAdminIdAsync({
  //     staffId:staffId
  //   }))
  // }

  // useEffect(()=>{
  //   userDetails();
  // },[])

  // console.log(userData,'userData');
  return (
    <>
      {/* <Link to={PATH_DASHBOARD.user.account} component={RouterLink} underline="none" color="inherit"> */}
      <StyledRoot>
        <CustomAvatar
          src={"http://www.gravatar.com/avatar/?d=mp"}
          alt={user?.displayName}
          name={user?.displayName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.user?.name}
          </Typography>
        </Box>
      </StyledRoot>
      {/* <StyledRoot style={{marginTop:20}}>
        <CustomAvatar alt={user?.displayName} name={user?.displayName} />

        <Box sx={{ ml: 1, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {'Dashboard'}
          </Typography>
        </Box>
        
      </StyledRoot> */}
      {/* </Link> */}
    </>
  );
}
