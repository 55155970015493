import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addBankDetailsAsync,
  putBankDetailsAsync,
  getBankDetailsByIdAsync,
  getBankdetailAsync,
  changeStatusBankDetailsAsync,
  deleteBankDetailsAsync,
} from "../async.api";

const initialState = {
  addingBankInfoLoader: false,
  addBankData: [],
  addBankInfoError: [],
  putBankData: [],
  deleteBankData: [],
  getBankDataById: [],
  getBankdetail: null,
  changeStatusBankDetails: [],
};

export const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addBankDetailsAsync.pending,
        putBankDetailsAsync.pending,
        getBankDetailsByIdAsync.pending,
        getBankdetailAsync.pending,
        deleteBankDetailsAsync.pending,
      ),
      (state) => {
        state.addingBankInfoLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(addBankDetailsAsync.fulfilled),
      (state, action) => {
        state.addingBankInfoLoader = false;
        console.log("addbankdata", action.payload);
        state.addBankData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(putBankDetailsAsync.fulfilled),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.putBankData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getBankDetailsByIdAsync.fulfilled,
        getBankDetailsByIdAsync.rejected
      ),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.getBankDataById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getBankdetailAsync.fulfilled, getBankdetailAsync.rejected),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.getBankdetail = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        changeStatusBankDetailsAsync.fulfilled,
        changeStatusBankDetailsAsync.rejected
      ),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.changeStatusBankDetails = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        deleteBankDetailsAsync.fulfilled,
        deleteBankDetailsAsync.rejected
      ),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.deleteBankData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        addBankDetailsAsync.rejected,
        putBankDetailsAsync.rejected,
        getBankDetailsByIdAsync.rejected,
        getBankdetailAsync.rejected
      ),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.addBankInfoError = action.payload;
      }
    );
  },
  reducers: {
    emptyBankInfo: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyBankInfo } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
