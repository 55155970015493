import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPayrollAsync ,putPayrollAsync} from '../async.api';


const initialState = {
  staffPayment: [],
  putStaffPayment:[],
  loading: false,
  
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPayrollAsync.pending,putPayrollAsync.pending), (state) => {
      state.loading = true;
    });
    builder.addMatcher(isAnyOf(getPayrollAsync.fulfilled), (state, action) => {
      state.staffPayment = action.payload;
      state.loading = false;
    });
    builder.addMatcher(isAnyOf(putPayrollAsync.fulfilled), (state, action) => {
      state.putStaffPayment = action.payload;
      state.loading = false;
    });
    builder.addMatcher(isAnyOf(getPayrollAsync.rejected,putPayrollAsync.rejected), (state) => {
      state.loading = false;
    
    });
  },
  reducers: {
    emptyPayrollInfo: () => initialState,
  },
});
export const { emptyPayrollInfo } = payrollSlice.actions;
export default payrollSlice.reducer;
