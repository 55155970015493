import {
  Box,
  Container,
  Button,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import {
  getAllAssetTypeAsync,
  deleteAssetTypeAsync,
} from "src/redux/async.api";

import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../pages/customTable";
import { PATH_DASHBOARD } from "../../../routes/paths";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import DialogBox from "src/components/Modal-DialogBoxForm/DialogBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddNewAsset from "./AddNewAsset";
import CircularProgress from "@mui/material/CircularProgress";
import MenuPopover from "../../../components/menu-popover/MenuPopover";
import Iconify from "src/components/iconify/Iconify";
import { useSettingsContext } from "src/components/settings";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { toast } from "react-hot-toast";
import { toastoptions } from "src/utils/toastoptions";
import { emptyAssetType } from "src/redux/slices/assetType.slice";
import { Helmet } from "react-helmet-async";

const AssetType = () => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettingsContext();
  const [AssetTypeId, setAssetTypeId] = useState("");
  const [open, setopenPopup] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { modulePermit } = useSelector((state) => state.menuPermission);
  const { getAssetTypeList, assetTypeLoader, deleteAssetType } =
    useSelector((state) => state.assetType);

  const handleOpenPopOver = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setOpenPopover(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDeleteRows = () => {
    dispatch(deleteAssetTypeAsync({ id: AssetTypeId })).then(() => {
      dispatch(getAllAssetTypeAsync());
    });
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (deleteAssetType.status === true) {
      toast.success(deleteAssetType?.message, toastoptions);
      dispatch(emptyAssetType());
    }
  }, [assetTypeLoader]);

  const columns = [
    {
      name: "Action",
      selector: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenPopOver(e);
              setAssetTypeId(row?._id);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        );
      },
    },
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Asset Type",
      selector: (row) => row.assetTypeName,
    },
  ];

  useEffect(() => {
    if (deleteAssetTypeAsync?.success === true) {
      setopenPopup(false);
      setOpenPopover(false);
    }
  }, [deleteAssetTypeAsync]);

  useEffect(() => {
    dispatch(getAllAssetTypeAsync());
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Asset Type | Amartal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"} sx={{ mt: 4 }}>
        <Container>
          <CustomBreadcrumbs
            heading="Asset Type"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: "Asset Type", href: PATH_DASHBOARD.staff.root },
            ]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  setopenPopup(true);
                  setAssetTypeId("");
                }}
                disabled={!modulePermit?.add}
              >
                Add New Asset type
              </Button>
            }
          />
        </Container>

        <CustomTable
          fixedHeader={false}
          paginations={false}
          columns={columns}
          data={getAssetTypeList?.data}
          loader={assetTypeLoader === true ? <CircularProgress /> : null}
        />

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopOver}
          arrow="left-top"
          sx={{ width: 130 }}
        >
          <MenuItem
            onClick={() => {
              setopenPopup(true);
              handleClosePopOver();
            }}
            disabled={!modulePermit?.edit}
          >
            <Iconify icon="eva:edit-fill" />
            Edit
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleOpenConfirm();
              handleClosePopOver();
            }}
            sx={{ color: "error.main" }}
            disabled={!modulePermit?.remove}
          >
            <Iconify icon="eva:trash-2-outline" />
            Delete
          </MenuItem>
        </MenuPopover>

        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title="Delete"
          content={<>Are you sure want to delete Asset Type.</>}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
              }}
            >
              Delete
            </Button>
          }
        />

        <DialogBox open={open}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <DialogTitle id="dialog-title" sx={{ padding: "0px" }}>
                {AssetTypeId ? "Update Asset Type" : "Add Asset Type"}{" "}
              </DialogTitle>
            </Box>
            <Box>
              <IconButton color="primary" size="large">
                <HighlightOffIcon
                  onClick={() => {
                    setopenPopup(false);
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          <AddNewAsset setopenPopup={setopenPopup} AssetTypeId={AssetTypeId} />
        </DialogBox>
      </Container>
    </Box>
  );
};

export default AssetType;
