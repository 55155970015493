import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getDepartmentAsync,
  DepartmentpostAsync,
  putDepartmentAsync,
  deleteDepartmentByIdAsync,
  getDepartmentByIdAsync,
} from "../async.api";

const initialState = {
  departmentLoader: false,
  departmentData: [],
  departmentadd: [],
  departmentError: [],
  deleteDepartmentData: [],
  putdepartmentRequest: [],
  departmenteditData: [],
  departmentDataById: [],
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getDepartmentAsync.pending,
        DepartmentpostAsync.pending,
        putDepartmentAsync.pending,
        deleteDepartmentByIdAsync.pending,
        getDepartmentByIdAsync.pending
      ),
      (state) => {
        state.departmentLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(DepartmentpostAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.departmentadd = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getDepartmentAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.departmentData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteDepartmentByIdAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.deleteDepartmentData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(putDepartmentAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.departmenteditData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getDepartmentByIdAsync.fulfilled),
      (state, action) => {
        state.registerStaffLoader = false;
        state.departmentDataById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putDepartmentAsync.fulfilled),
      (state, action) => {
        state.allLeaveLoader = false;
        state.putdepartmentRequest = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getDepartmentAsync.rejected,
        DepartmentpostAsync.rejected,
        putDepartmentAsync.rejected,
        deleteDepartmentByIdAsync.rejected,
        getDepartmentByIdAsync.rejected
      ),
      (state, action) => {
        state.departmentLoader = false;
      }
    );
  },

  reducers: {
    emptyDepartment: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
