import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  postTaskAsync,
  getTaskAsync,
  getTaskUserAsync,
  deleteTaskByIdAsync,
  putTaskAsync,
  getTaskByIdAsync,
  putTaskStatusAsync,
} from "../async.api";

const initialState = {
  allTask: [],
  taskLoader: false,
  postTask: [],
  putTask: [],
  getTaskById: [],
  deleteDataTask: [],
  getTaskUserDropdown: [],
  putTaskStatus: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        postTaskAsync.pending,
        getTaskAsync.pending,
        deleteTaskByIdAsync.pending,
        putTaskAsync.pending,
        putTaskStatusAsync.pending,
        getTaskByIdAsync.pending
      ),
      (state) => {
        state.taskLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(getTaskUserAsync.pending), (state) => {});

    builder.addMatcher(isAnyOf(getTaskByIdAsync.fulfilled), (state, action) => {
      state.taskLoader = false;
      state.getTaskById = action.payload;
    });

    builder.addMatcher(isAnyOf(getTaskUserAsync.fulfilled), (state, action) => {
      state.taskLoader = false;
      state.getTaskUserDropdown = action.payload;
    });

    builder.addMatcher(isAnyOf(postTaskAsync.fulfilled), (state, action) => {
      state.taskLoader = false;
      state.postTask = action.payload;
    });

    builder.addMatcher(isAnyOf(getTaskAsync.fulfilled), (state, action) => {
      state.taskLoader = false;
      state.allTask = action.payload;
    });

    builder.addMatcher(
      isAnyOf(deleteTaskByIdAsync.fulfilled),
      (state, action) => {
        state.taskLoader = false;
        state.deleteDataTask = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(putTaskAsync.fulfilled), (state, action) => {
      state.taskLoader = false;
      state.putTask = action.payload;
    });
    builder.addMatcher(
      isAnyOf(putTaskStatusAsync.fulfilled),
      (state, action) => {
        state.taskLoader = false;
        state.putTaskStatus = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        postTaskAsync.rejected,
        getTaskAsync.rejected,
        deleteTaskByIdAsync.rejected,
        putTaskAsync.rejected,
        putTaskStatusAsync.rejected,
        getTaskByIdAsync.rejected
      ),
      (state) => {
        state.taskLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(getTaskUserAsync.rejected), (state) => {
      state.taskLoader = false;
    });
  },

  reducers: {
    emptyTask: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyTask } = taskSlice.actions;
export default taskSlice.reducer;
