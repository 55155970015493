import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllServicesAsync,
  postServiceAsync,
  getServiceByIdAsync,
  putServiceAsync,
  deleteServiceAsync,
} from "../async.api";

const initialState = {
  allServiceLoader: false,
  getAllServiceData: [],
  allServiceError: [],
  getServiceById: [],
  putService: [],
  postService: [],
  deleteService: [],
};

export const allServiceSlice = createSlice({
  name: "allServiceSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllServicesAsync.pending,
        getServiceByIdAsync.pending,
        putServiceAsync.pending,
        postServiceAsync.pending,
        deleteServiceAsync.pending
      ),
      (state) => {
        state.allServiceLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllServicesAsync.fulfilled),
      (state, action) => {
        state.allServiceLoader = false;
        state.getAllServiceData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getServiceByIdAsync.fulfilled),
      (state, action) => {
        state.allServiceLoader = false;
        state.getServiceById = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(putServiceAsync.fulfilled), (state, action) => {
      state.allServiceLoader = false;
      state.putService = action.payload;
    });

    builder.addMatcher(isAnyOf(postServiceAsync.fulfilled), (state, action) => {
      state.allServiceLoader = false;
      state.postService = action.payload;
    });

    builder.addMatcher(
      isAnyOf(deleteServiceAsync.fulfilled),
      (state, action) => {
        state.allServiceLoader = false;
        state.deleteService = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllServicesAsync.rejected,
        getServiceByIdAsync.rejected,
        putServiceAsync.rejected,
        postServiceAsync.rejected,
        deleteServiceAsync.rejected
      ),
      (state, action) => {
        state.allServiceLoader = false;
        state.allServiceError = action.payload;
      }
    );
  },

  reducers: {
    emptyAllService: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyAllService } = allServiceSlice.actions;
export default allServiceSlice.reducer;
