import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllAdvanceListAsync,
  getAllStaffForAdvanceAsync,
  getAllStaffByProjectIdAsync,
  addAdvanceAsync,
  getAdvanceDataByIdAsync,
  putAdvanceDataAsync,
  deleteAdvanceAsync,
} from "../async.api";

const initialState = {
  advanceLoader: false,
  getAllAdvanceList: [],
  addAdvance: [],
  putAdvanceData: [],
  getAdvanceDataById: [],
  deleteAdvance: [],
  getAllStaffForAdvance: [],
  getAllStaffByProjectId:[],
};

const advanceSlice = createSlice({
  name: "advance",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addAdvanceAsync.pending,
        getAllAdvanceListAsync.pending,
        deleteAdvanceAsync.pending,
        putAdvanceDataAsync.pending,
        getAdvanceDataByIdAsync.pending,
        getAllStaffForAdvanceAsync.pending,
        getAllStaffByProjectIdAsync.pending
      ),
      (state) => {
        state.advanceLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllAdvanceListAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.getAllAdvanceList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAdvanceDataByIdAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.getAdvanceDataById = action.payload;
      }
    );

   

    builder.addMatcher(isAnyOf(addAdvanceAsync.fulfilled), (state, action) => {
      state.advanceLoader = false;
      state.addAdvance = action.payload;
    });

    builder.addMatcher(
      isAnyOf(putAdvanceDataAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.putAdvanceData = action.payload;
      }
    );

     builder.addMatcher(
      isAnyOf(deleteAdvanceAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.deleteDataTask = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllStaffForAdvanceAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.getAllStaffForAdvance = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllStaffByProjectIdAsync.fulfilled),
      (state, action) => {
        state.advanceLoader = false;
        state.getAllStaffByProjectId = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllAdvanceListAsync.rejected,
        addAdvanceAsync.rejected,
        deleteAdvanceAsync.rejected,
        putAdvanceDataAsync.rejected,
        getAdvanceDataByIdAsync.rejected,
        getAllStaffForAdvanceAsync.rejected,
        getAllStaffByProjectIdAsync.rejected,
      ),
      (state) => {
        state.advanceLoader = true;
      }
    );
  },

  reducers: {
    emptyAdvance: (state) => {
      return {
        getAllStaffByProjectId :[],
        // ...initialState,
        initialState
        
      };
    },
  },
});

export const { emptyAdvance } = advanceSlice.actions;
export default advanceSlice.reducer;
