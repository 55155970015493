import axios from "axios";
// import { API_URL } from "../utils/secret";
import { errorMessage } from "./slices/error";
// *
//  * Axios Api Call Component
//  * @type : GET POST PATCH DELETE
//  * @api : Api Path
//  * @payload : Payload that need to be sent to server
//  * @toolkit: dispatch, fulfillWithValue, rejectWithValue
//  */

const AxiosClient = async (
  type,
  api,
  payload,
  toolkit,
  headerType = "application/json"
) => {
  const AxiosTypeString = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
  };

  return await axios({
    method: AxiosTypeString[type],
    url: `${process.env.REACT_APP_BASE_URL}${api}`,
    data: payload,
    headers: {
      "Content-Type": headerType,
      Authorization:  localStorage.getItem("token") || null,
    },
  })
    .then((response) => {
      return toolkit.fulfillWithValue(response.data);
    })
    .catch((error) => {
      toolkit.dispatch(errorMessage(error.response.data.message));
      return toolkit.rejectWithValue(error.response.data.message);
    });
};

export { AxiosClient };

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response.status === 401 ) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      if (window.location.pathname !== "/auth/login") {
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);