import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {getAllLeaveListAsync} from "../async.api";

 const initialState = {
    leaveLoader:false,
    leaveError:[],
    getAllLeaveList:[],

 }

export const getleaveLeaveSlice = createSlice({
    name:"applyleave",
    initialState,
    extraReducers:(builder)=>{
        builder.addMatcher(isAnyOf(getAllLeaveListAsync.pending),(state)=>{
            state.leaveLoader= true;
        });
          builder.addMatcher(isAnyOf(getAllLeaveListAsync.fulfilled), (state, action) => {
            state.leaveLoader = false;
            state.getAllLeaveList = action.payload;
          });
          builder.addMatcher(isAnyOf(getAllLeaveListAsync.rejected), (state, action) => {
            state.leaveLoader = false;
            state.leaveError = action.payload;
          });
          
    },
    reducers: {
        emptyleave: (state) => {
          return {
            ...initialState,
          };
        },
      },
})

 export const { emptyleave } = getleaveLeaveSlice.actions;

export default getleaveLeaveSlice.reducer;