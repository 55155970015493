import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRouteByRoleId: {},
};

export const userInfoSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserInfoRedux: (state, action) => {
      state.allRouteByRoleId = action.payload;
    },
    // eslint-disable-next-line arrow-body-style
    emptyuserinfo: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyuserinfo, setUserInfoRedux } = userInfoSlice.actions;

export default userInfoSlice.reducer;
