import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slices/login.slice";
import registerStaffSlice from "./slices/registerStaff.slice";
import dashboardSlice from "./slices/DashboardSlice/dashboard.slice";
import bankDetailsSlice from "./slices/bankDetails.slice";
import departmentSlice from "./slices/department.slice";
import attendanceSlice from "./slices/attendance.slice";
import error from "./slices/error";
import monthlyAttendanceSlice from "./slices/monthlyAttendance.slice";
import userdetailsSlice from "./slices/usernameInNav.slice";
import applyLeaveSlice from "./slices/applyLeave.slice";
import allLeaveSlice from "./slices/allLeave.slice";
// my
import companySlice from "./slices/company.slice";
import ExpenseDetailsSlice from "./slices/expenses.slice";
import getleaveSlice from "./slices/getleave.slice";
import allRolesSlice from "./slices/roles.slice";
import descriptionSlice from "./slices/decsription.slice";
import subDescriptionSlice from "./slices/subdescription.slice";
import payrollSlice from "./slices/payroll.slice";
import assetSlice from "./slices/assets.slice";
import assignAssetSlice from "./slices/assignAsset.slice";
import taskSlice from "./slices/task.slice";

// AssetType
import assetTypeSlice from "./slices/assetType.slice";
import holidaySlice from "./slices/holiday.slice";

// Customer
import customerSlice from "./slices/customer.slice";

//Resume---
import invoiceSlice from "./slices/invoice.slice";
import serviceSlice from "./slices/service.slice";
import permissionSlice from "./slices/permission.slice";
import menuPermissionSlice from "./slices/menuPermission.slice";
import userInfoSlice from "./slices/userInfo.slice";
import leaveBalanceSlice from "./slices/leaveBalance.slice";
import signUpSlice from "./slices/signupSlice";
import advanceSlice from "./slices/advance.slice";
//Uan And Esi -----------------
import uanAndEsiSlice from "./slices/uanAndEsi.slice";

// -------------//----------------------

export const store = configureStore({
  reducer: {
    login: loginSlice,
    signUp: signUpSlice,
    registerStaff: registerStaffSlice,
    dashboard: dashboardSlice,
    attendance: attendanceSlice,
    bankDetails: bankDetailsSlice,
    error: error,
    monthlyAttendance: monthlyAttendanceSlice,
    userdetails: userdetailsSlice,
    applyleave: applyLeaveSlice,
    allLeaveSlice: allLeaveSlice,
    department: departmentSlice,
    getleave: getleaveSlice,
    company: companySlice,
    expenseDetails: ExpenseDetailsSlice,
    rolesSlice: allRolesSlice,
    descriptionSlice: descriptionSlice,
    subDescriptionSlice: subDescriptionSlice,
    assetType: assetTypeSlice,
    payroll: payrollSlice,
    addAssetSlice: assetSlice,
    assignAsset: assignAssetSlice,
    asset: assetSlice,
    task: taskSlice,
    holidays: holidaySlice,
    invoiceSlice: invoiceSlice,
    serviceSlice: serviceSlice,
    invoice: invoiceSlice,
    service: serviceSlice,
    permission: permissionSlice,
    menuPermission: menuPermissionSlice,
    userInfo: userInfoSlice,
    leaveBalance: leaveBalanceSlice,
    customer : customerSlice,
    advance: advanceSlice,
    uanAndEsi: uanAndEsiSlice,
  },
});
