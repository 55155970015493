// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

// lightbox
import "react-image-lightbox/style.css";

// map
import "./utils/mapboxgl";
import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// redux
import { store } from "./redux/store";
import { Provider } from "react-redux";
// components
import { SettingsProvider } from "./components/settings";
import ScrollToTop from "./components/scroll-to-top";

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from "./auth/JwtContext";
import ErrorMiddleWare from "../src/layouts/Middleware/ErrorMiddleWare";
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

//
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <AuthProvider>
//     <HelmetProvider>
//       <ReduxProvider store={store}>
//         {/* <PersistGate loading={null} persistor={persistor}> */}
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//           <SettingsProvider>
//             <BrowserRouter>
//               <ScrollToTop />
//               <App />
//             </BrowserRouter>
//           </SettingsProvider>
//         </LocalizationProvider>
//         {/* </PersistGate> */}
//       </ReduxProvider>
//     </HelmetProvider>
//   </AuthProvider>
// );
root.render(
  <AuthProvider>
    <HelmetProvider>
      <Provider store={store}>
        {/* <SiteInfoMiddleWare> */}
        <ErrorMiddleWare>
          <SettingsProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SettingsProvider>
        </ErrorMiddleWare>
        {/* </SiteInfoMiddleWare> */}
      </Provider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
