// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  cart: icon("ic_cart"),
  user: icon("ic_user"),
  inventory: icon("inventory-2"),
  tasks: icon("task-fill"),
  invoices: icon("invoice-solid"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  attendance: icon("ic_attendance"),
  master: icon("ic_master"),
  setting: icon("ic_setting"),
  payroll: icon("ic_payroll"),
  leavemanagment: icon("leave_management"),
  resume: icon("ic_resume"),
  leadmanagement: icon("ic_leads"),
  calendar: icon("ic_calendar"),
  dashboard: icon("ic_dashboard"),
  invoice: icon("ic_invoice"),
  event: icon("ic_event"),
  project: icon("ic_project"),
  advance: icon("ic_advance"),
  uanAndEsi: icon('ic_uan_esi')
};

const navConfig = [
  // GENERAL
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: "Attendance",
        path: PATH_DASHBOARD.attendance.list,
        icon: ICONS.attendance,
      },
      {
        title: "Monthly Attendance",
        path: PATH_DASHBOARD.general.monthly,
        icon: ICONS.booking,
      },
      {
        title: "Events",
        path: PATH_DASHBOARD.general.event,
        icon: ICONS.event,
      },
      {
        title: "Staff",
        path: PATH_DASHBOARD.staff.list,
        icon: ICONS.user,
      },
      {
        title: "Staff Bank Detail",
        path: PATH_DASHBOARD.bankdetail.list,
        icon: ICONS.user,
      },
      {
        title: "Projects",
        path: PATH_DASHBOARD.project.list,
        icon: ICONS.project,
      },
      {
        title: " Master",
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.master,
        children: [
          { title: "Department", path: PATH_DASHBOARD.master.department },
          { title: "Designation", path: PATH_DASHBOARD.master.roles },
          { title: "Services", path: PATH_DASHBOARD.master.services },
          { title: "Description", path: PATH_DASHBOARD.master.description },
          {
            title: "Sub Description",
            path: PATH_DASHBOARD.master.subdescription,
          },
        ],
      },
      {
        title: "Expense",
        path: PATH_DASHBOARD.expense.root,
        icon: ICONS.banking,
      },

      {
        title: "General Settings",
        path: PATH_DASHBOARD.GeneralSetting.root,
        icon: ICONS.setting,
        children: [
          { title: "Holidays", path: PATH_DASHBOARD.GeneralSetting.holiday },
        ],
      },
      {
        title: "Leave Management",
        path: PATH_DASHBOARD.leavemanagment.root,
        icon: ICONS.leavemanagment,
        children: [
          { title: "My Leave", path: PATH_DASHBOARD.leavemanagment.table },
          { title: "Apply Leave", path: PATH_DASHBOARD.leavemanagment.new },
          { title: "All Leave", path: PATH_DASHBOARD.leavemanagment.list },
        ],
      },
      {
        title: "Payroll",
        path: PATH_DASHBOARD.payroll.root,
        icon: ICONS.payroll,
      },
      {
        title: "UAN And ESI",
        path: PATH_DASHBOARD.uanandesi.root,
        icon: ICONS.uanAndEsi,
      },
      {
        title: "Deduction",
        path: PATH_DASHBOARD.advance.root,
        icon: ICONS.advance,
      },
      {
        title: "Stock Management",
        path: PATH_DASHBOARD.InventoryManagement.root,
        icon: ICONS.inventory,
        children: [
          {
            title: "Asset Type",
            path: PATH_DASHBOARD.InventoryManagement.assettype,
          },
          {
            title: "Add Asset",
            path: PATH_DASHBOARD.InventoryManagement.addAsset,
          },
          {
            title: "Assign Asset",
            path: PATH_DASHBOARD.InventoryManagement.assignAsset,
          },
        ],
      },
      {
        title: "Customer",
        path:PATH_DASHBOARD.customer.list,
        icon: ICONS.user,
      },

      {
        title: "Task Management",
        path: PATH_DASHBOARD.TaskManagement.root,
        icon: ICONS.tasks,
        children: [
          { title: "Add Task", path: PATH_DASHBOARD.TaskManagement.addTask },
        ],
      },
      {
        title: "Invoice Management",
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [{ title: "list", path: PATH_DASHBOARD.invoice.list }],
      },
    ],
  },
];

export default navConfig;
