import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getLeaveBalanceAsync } from "../async.api";

const initialState = {
  getLeaveBalance: [],
  loading: false,
};

const leavBalanceSlice = createSlice({
  name: "leaveBalance",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getLeaveBalanceAsync.pending), (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      isAnyOf(getLeaveBalanceAsync.fulfilled),
      (state, action) => {
        state.loading = false;
        state.getLeaveBalance = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getLeaveBalanceAsync.rejected), (state) => {
      state.loading = false;
    });
  },
  reducers: {
    emptyLeaveBalance: () => initialState,
  },
});
export const { emptyLeaveBalance } = leavBalanceSlice.actions;
export default leavBalanceSlice.reducer;
