import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "src/redux/AxiosClient";

export const getDashboardAsync = createAsyncThunk(
  "admin/getDashboard",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/counts?projectId=${payload?.projectId || ''}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getAllDashboardDataAsync = createAsyncThunk("admin/getDashboardData",async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
  return await AxiosClient("GET", `api/v1/getCompanyData/${payload?.id || ''}`, [], {
    dispatch,
    rejectWithValue,
    fulfillWithValue,
  });
}
);

// ==================================== Dashboard Salary Slip ========================================

export const getSalarySlipDataByMonthAsync = createAsyncThunk(
  "dashboard/getSalarySlipDataByMonth",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/totalSalary/${payload?.staffId || ''}?month=${payload.month || ''}&year=${payload.year || ''}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

