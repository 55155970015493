import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllHolidayAsync,
  addHolidayAsync,
  deleteHolidayByIdAsync,
  putHolidayAsync,
  getSingleHolidayAsync,
} from "../async.api";

const initialState = {
  allHolidays: [],
  singleHoliday: [],
  addHoliday: [],
  deleteHoliday: [],
  updateHoliday: [],
  loader: false,
};

const holidaySlice = createSlice({
  name: "holidays",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getSingleHolidayAsync.pending,
        getAllHolidayAsync.pending,
        addHolidayAsync.pending,
        putHolidayAsync.pending,
        deleteHolidayByIdAsync.pending
      ),
      (state) => {
        state.loader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllHolidayAsync.fulfilled),
      (state, action) => {
        state.allHolidays = action.payload;
        state.loader = false;
      }
    );
    builder.addMatcher(
      isAnyOf(getSingleHolidayAsync.fulfilled),
      (state, action) => {
        state.singleHoliday = action.payload;
        state.loader = false;
      }
    );
    builder.addMatcher(isAnyOf(addHolidayAsync.fulfilled), (state, action) => {
      state.addHoliday = action.payload;
      state.loader = false;
    });
    builder.addMatcher(
      isAnyOf(deleteHolidayByIdAsync.fulfilled),
      (state, action) => {
        state.deleteHoliday = action.payload;
        state.loader = false;
      }
    );
    builder.addMatcher(
      isAnyOf(putHolidayAsync.fulfilled),
      (state, action) => {
        state.updateHoliday = action.payload;
        state.loader = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getSingleHolidayAsync.rejected,
        getAllHolidayAsync.rejected,
        addHolidayAsync.rejected,
        putHolidayAsync.rejected,
        deleteHolidayByIdAsync.rejected
      ),
      (state) => {
        state.loader = false;
      }
    );
  },
  reducers: {
    emptyHoliday: () =>{
      return {
        ...initialState,
      };
    }
  },
});
export const { emptyHoliday } = holidaySlice.actions;
export default holidaySlice.reducer;
