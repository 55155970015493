import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getSubDescriptionAsync,
  getSubDescriptionByIdAsync,
  addSubDescriptionAsync,
  putSubDescriptionAsync,
  deleteSingleSubDescriptionAsync,
  getSubDescriptionByDescriptionIdAsync,
} from "../async.api";

const initialState = {
  subDescriptionLoader: false,
  getSubDescriptionList: [],
  addSubDescription: [],
  putSubDescription: [],
  getSubDescriptionById: [],
  deleteSubDescription: [],
  getSubDescriptionByDescriptionId: [],
};

export const subDescriptionSlice = createSlice({
  name: "subDescriptionSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getSubDescriptionAsync.pending,
        addSubDescriptionAsync.pending,
        putSubDescriptionAsync.pending,
        getSubDescriptionByIdAsync.pending,
        deleteSingleSubDescriptionAsync.pending
      ),
      (state) => {
        state.subDescriptionLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getSubDescriptionAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        state.getSubDescriptionList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(addSubDescriptionAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        console.log("addRoleSlice", action.payload);
        state.addSubDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putSubDescriptionAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        state.putSubDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteSingleSubDescriptionAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        state.deleteSubDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getSubDescriptionByDescriptionIdAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        state.getSubDescriptionByDescriptionId = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getSubDescriptionByIdAsync.fulfilled),
      (state, action) => {
        state.subDescriptionLoader = false;
        state.getSubDescriptionById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getSubDescriptionAsync.rejected,
        addSubDescriptionAsync.rejected,
        putSubDescriptionAsync.rejected,
        getSubDescriptionByIdAsync.rejected,
        deleteSingleSubDescriptionAsync.rejected
      ),
      (state) => {
        state.subDescriptionLoader = false;
      }
    );
  },

  reducers: {
    emptysubDescription: () => initialState,
  },
});

export const { emptysubDescription } = subDescriptionSlice.actions;

export default subDescriptionSlice.reducer;
