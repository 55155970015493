import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addStaffAsync,
  getStaffAsync,
  deleteStaffByIdAsync,
  putStaffAsync,
  getStaffByIdAsync,
  putStaffStatusAsync,
  putChangePaswordAsync,
  addAssignSubStaffAsync,
  getSubStaffAsync,
  getStaffAsignAsync,
  getDailyAttendanceAsync,
  assignDepartmentAndDesignationAsync,
  getDepartmentAndDesignationByIdAsync
} from "../async.api";

const initialState = {
  registerStaffLoader: false,
  staffData: [],
  deleteData: [],
  staffDataById: [],
  registerStaffError: [],
  dash: [],
  staffeditdata: [],
  staffStatusActiveInactive: [],
  changePassword: [],
  postAssignSubStaff:[],
  getSubStaff:[],
  getStaffAsign:[],
  getDailyAttendance:[],
  assignDepartmentAndDesignation:[],
  getDepartmentAndDesignationById:[],
};

export const registerStaffSlice = createSlice({
  name: "registerStaff",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addStaffAsync.pending,
        getStaffAsync.pending,
        deleteStaffByIdAsync.pending,
        getStaffByIdAsync.pending,
        putStaffAsync.pending,
        putStaffStatusAsync.pending,
        addAssignSubStaffAsync.pending,
        getSubStaffAsync.pending,
        getStaffAsignAsync.pending,
        getDailyAttendanceAsync.pending,
        assignDepartmentAndDesignationAsync.pending,
        getDepartmentAndDesignationByIdAsync.pending,
      ),
      (state) => {
        state.registerStaffLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getSubStaffAsync.pending
      ),
      (state) => {
        state.registerStaffLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(addStaffAsync.fulfilled), (state, action) => {
      state.registerStaffLoader = false;
      state.registerStaff = action.payload;
    });
    builder.addMatcher(isAnyOf(getStaffAsignAsync.fulfilled), (state, action) => {
      state.registerStaffLoader = false;
      state.getStaffAsign = action.payload;
    });
    builder.addMatcher(isAnyOf(getStaffAsync.fulfilled), (state, action) => {
      state.registerStaffLoader = false;
      state.staffData = action.payload?.data;
    });
    builder.addMatcher(
      isAnyOf(deleteStaffByIdAsync.fulfilled),
      (state, action) => {
        state.registerStaffLoader = false;
        state.deleteData = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(putStaffAsync.fulfilled), (state, action) => {
      state.registerStaffLoader = false;
      state.staffeditdata = action.payload;
    });

    builder.addMatcher(
      isAnyOf(getStaffByIdAsync.fulfilled),
      (state, action) => {
        state.registerStaffLoader = false;
        state.staffDataById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putStaffStatusAsync.fulfilled),
      (state, action) => {
        state.registerStaffLoader = false;
        state.staffStatusActiveInactive = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putChangePaswordAsync.fulfilled),
      (state, action) => {
        state.registerStaffLoader = false;
        state.changePassword = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getSubStaffAsync.fulfilled),(state,action) =>{
      state.registerStaffLoader = false;
      state.getSubStaff=action.payload;
    })
    builder.addMatcher(isAnyOf(getDailyAttendanceAsync.fulfilled),(state,action) =>{
      state.registerStaffLoader = false;
      state.getDailyAttendance = action.payload;
    })
 
    builder.addMatcher(isAnyOf(addAssignSubStaffAsync.fulfilled),(state,action) =>{
      state.registerStaffLoader = false;
      state.postAssignSubStaff = action.payload;
    })
    builder.addMatcher(isAnyOf(assignDepartmentAndDesignationAsync.fulfilled),(state,action) =>{
      state.registerStaffLoader = false;
      state.assignDepartmentAndDesignation = action.payload;
    })
    builder.addMatcher(isAnyOf(getDepartmentAndDesignationByIdAsync.fulfilled),(state,action) =>{
      state.registerStaffLoader = false;
      state.getDepartmentAndDesignationById = action.payload;
    })
    builder.addMatcher(
      isAnyOf(
        addStaffAsync.rejected,
        putStaffAsync.rejected,
        getStaffAsync.rejected,
        getSubStaffAsync.rejected,
        addAssignSubStaffAsync.rejected,
        getStaffAsignAsync.rejected,
        getDailyAttendanceAsync.rejected,
        assignDepartmentAndDesignationAsync.rejected,
        getDepartmentAndDesignationByIdAsync.rejected,
      ),
      (state) => {
        state.registerStaffLoader = false;
      }
    )
  },

  reducers: {
    emptyregisterStaff: () => initialState,
  },
});

export const { emptyregisterStaff } = registerStaffSlice.actions;

export default registerStaffSlice.reducer;
