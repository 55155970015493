import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllUanAndEsiListAsync,
  addUanAndEsiAsync,
  getUanAndEsiByIdAsync,
  putUanAndEsiAsync,
  deleteUanAndEsiAsync,
} from "../async.api";

const initialState = {
  uanAndEsiLoader: false,
  getAllUanAndEsiList: [],
  addUanAndEsi: [],
  putUanAndEsi: [],
  getUanAndEsiById: [],
  deleteUanAndEsi: [],
};

const uanAndEsiSlice = createSlice({
  name: "uanAndEsi",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addUanAndEsiAsync.pending,
        getAllUanAndEsiListAsync.pending,
        deleteUanAndEsiAsync.pending,
        putUanAndEsiAsync.pending,
        getUanAndEsiByIdAsync.pending,
      ),
      (state) => {
        state.uanAndEsiLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllUanAndEsiListAsync.fulfilled),
      (state, action) => {
        state.uanAndEsiLoader = false;
        state.getAllUanAndEsiList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getUanAndEsiByIdAsync.fulfilled),
      (state, action) => {
        state.uanAndEsiLoader = false;
        state.getUanAndEsiById = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(addUanAndEsiAsync.fulfilled), (state, action) => {
      state.uanAndEsiLoader = false;
      state.addUanAndEsi = action.payload;
    });

    builder.addMatcher(
      isAnyOf(putUanAndEsiAsync.fulfilled),
      (state, action) => {
        state.uanAndEsiLoader = false;
        state.putUanAndEsi = action.payload;
      }
    );

     builder.addMatcher(
      isAnyOf(deleteUanAndEsiAsync.fulfilled),
      (state, action) => {
        state.uanAndEsiLoader = false;
        state.deleteUanAndEsi = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllUanAndEsiListAsync.rejected,
        addUanAndEsiAsync.rejected,
        deleteUanAndEsiAsync.rejected,
        putUanAndEsiAsync.rejected,
        getUanAndEsiByIdAsync.rejected,
      ),
      (state) => {
        state.uanAndEsiLoader = true;
      }
    );
  },

  reducers: {
    emptyTask: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyUanAndEsi } = uanAndEsiSlice.actions;
export default uanAndEsiSlice.reducer;
