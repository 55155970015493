import { createSlice , isAnyOf } from "@reduxjs/toolkit";
import {getMonthlyAttendanceAysnc ,getCSVDataForStaffAttendenceAsync} from '../async.api';

const initialState = {
    montlhyAttendLoader :false,
    monthlyAttendData: [] ,
    getCSVDataForStaff:[],
    monthlyError:[]
}

export const MontlySlice = createSlice({
    name:"monthlyAttendance",
    initialState,
    extraReducers:(builder)=>{
        builder.addMatcher(isAnyOf(getMonthlyAttendanceAysnc.pending ),(state)=>{
            state.montlhyAttendLoader= true;
        });
        builder.addMatcher(isAnyOf(getMonthlyAttendanceAysnc.fulfilled), (state, action) => {
            state.montlhyAttendLoader = false;
           
            state.monthlyAttendData = action.payload;
          });
          builder.addMatcher(isAnyOf(getMonthlyAttendanceAysnc.rejected), (state, action) => {
            state.montlhyAttendLoader = false;
            state.monthlyError = action.payload;
          });
   
      builder.addMatcher(isAnyOf(getCSVDataForStaffAttendenceAsync.pending ),(state)=>{
          state.montlhyAttendLoader= true;
      });
      builder.addMatcher(isAnyOf(getCSVDataForStaffAttendenceAsync.fulfilled), (state, action) => {
          state.montlhyAttendLoader = false;
         
          state.getCSVDataForStaff = action.payload;
        });
        builder.addMatcher(isAnyOf(getCSVDataForStaffAttendenceAsync.rejected), (state, action) => {
          state.montlhyAttendLoader = false;
          state.getCSVDataForStaff = action.payload;
        });    
  },
    
    reducers: {
        emptymonthlyattendance: (state) => {
          return {
            ...initialState,
          };
        },
      },
})

 export const { emptymonthlyattendance } = MontlySlice.actions;

export default MontlySlice.reducer;