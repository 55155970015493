import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllAssetTypeAsync,
  addAssetTypeAsync,
  putAssetTypeAsync,
  getAssetTypeByIdAsync,
  deleteAssetTypeAsync,
} from "../async.api";

const initialState = {
  assetTypeLoader: false, 
  getAssetTypeList: [], 
  addAssetType: [], 
  putAssetType: [],
  getAssetTypeById: {}, 
  deleteAssetType: [],
};

const assetTypeSlice = createSlice({
  name: "assetType",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllAssetTypeAsync.pending,
        addAssetTypeAsync.pending,
        putAssetTypeAsync.pending,
        getAssetTypeByIdAsync.pending,
        deleteAssetTypeAsync.pending
      ),
      (state) => {
        state.assetTypeLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAssetTypeAsync.fulfilled),
      (state, action) => {
        state.assetTypeLoader = false;
        state.getAssetTypeList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(addAssetTypeAsync.fulfilled),
      (state, action) => {
        state.assetTypeLoader = false;
        state.addAssetType = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putAssetTypeAsync.fulfilled),
      (state, action) => {
        state.assetTypeLoader = false;
        state.putAssetType = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAssetTypeByIdAsync.fulfilled),
      (state, action) => {
        state.assetTypeLoader = false;
        state.getAssetTypeById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteAssetTypeAsync.fulfilled),
      (state, action) => {
        state.assetTypeLoader = false;
        state.deleteAssetType = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllAssetTypeAsync.rejected,
        addAssetTypeAsync.rejected,
        putAssetTypeAsync.rejected,
        getAssetTypeByIdAsync.rejected,
        deleteAssetTypeAsync.rejected
      ),
      (state) => {
        state.assetTypeLoader = true;
      }
    );
  },

  reducers: {
    emptyAssetType: () => initialState,
  },
});

export const { emptyAssetType } = assetTypeSlice.actions;

export default assetTypeSlice.reducer;
