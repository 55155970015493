import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "./AxiosClient";

export const adminsignupAsync = createAsyncThunk(
  "admin/adminsignup",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/adminsignup`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const postSignUpAsync = createAsyncThunk(
  "admin/adminSignUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/staff/create-staff`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getloginAsync = createAsyncThunk(
  "admin/adminlogin",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/staff/login-staff`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const addStaffAsync = createAsyncThunk(
  "admin/regisStaff",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/staff/admin/registerStaff`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// get all staff api
export const getStaffAsync = createAsyncThunk(
  "admin/getStaffAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/list-staff?page=1&limit=&search=${
        payload?.searchAll || ""
      }&department=${payload?.department || ""}&projectId=${
        payload?.projectId || ""
      }&employeeType=${
        payload?.employeeType || ""
      }`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// get all staff api
export const getStaffLoginAsync = createAsyncThunk(
  "admin/getStaffLoginAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/loginas/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getDailyAttendanceAsync = createAsyncThunk(
  "admin/getDailyAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSpecificDashboardDetail?staffId=${
        payload?.staffId
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getStaffAsignAsync = createAsyncThunk(
  "admin/getStaffAsign",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/staff/list-staff`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// cut
export const putStaffAsync = createAsyncThunk(
  "admin/putStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/staff/update-staff/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getStaffByIdAsync = createAsyncThunk(
  "admin/getStaffById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/get-staff/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteStaffByIdAsync = createAsyncThunk(
  "admin/delUser/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/staff/delete-staff/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getSubStaffAsync = createAsyncThunk(
  "admin/getSubStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/getSubStaffs/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addAssignSubStaffAsync = createAsyncThunk(
  "admin/assignStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/staff/addSubStaff`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// updatding Active/Inactive status

export const putStaffStatusAsync = createAsyncThunk(
  "admin/activeInactive/staff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/staff/statusChange/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getAttendAsync = createAsyncThunk(
  "admin/getAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/dashboardDetails?search=${payload.date || ""}&searchAll=${
        payload.searchAll || ""
      }&status=${payload.status || ""}&projectId=${payload.projectId || ""}`,
      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putCheckedDataAsync = createAsyncThunk(
  "admin/putCheckedDataAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/updateManyStatus`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// updatding attendance status
export const putAttendanceStatusAsync = createAsyncThunk(
  "admin/putAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateStatus/${payload?.staffId}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Assign Department and Designation
export const assignDepartmentAndDesignationAsync = createAsyncThunk(
  "admin/assignDepartmentAndDesignation",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/staff/assign-designation-department/${payload?.staffId}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Assign Department and Designation
export const getDepartmentAndDesignationByIdAsync = createAsyncThunk(
  "admin/getDepartmentAndDesignationById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/show-department-designation/${payload?.staffId}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Monthly attendance api
export const getMonthlyAttendanceAysnc = createAsyncThunk(
  "admin/getMonthlyAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staffDashboard?page=${payload?.page || ""}&limit=${
        payload.limit || ""
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&projectId=${payload?.projectId || ""}&name=${payload?.name || ""}`,
      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//GET
export const getCSVDataForStaffAttendenceAsync = createAsyncThunk(
  "admin/getCSVDataForStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const token = "Bearer " + localStorage.getItem("token") ?? null;
      const url = `${process.env.REACT_APP_BASE_URL}api/v1/getCSVDataForStaffAttendence?search=${payload}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&token=${token}`;
      window.open(url, "_blank");
      return "Success";
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Bank Post api
export const addBankDetailsAsync = createAsyncThunk(
  "admin/addBankDetails",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/bank`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Bank put api
export const putBankDetailsAsync = createAsyncThunk(
  "admin/putBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/bank/${payload?.userid}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Bank put api
export const deleteBankDetailsAsync = createAsyncThunk(
  "admin/putBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("DELETE", `api/v1/bank/${payload?.userid}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Change Status put api
export const changeStatusBankDetailsAsync = createAsyncThunk(
  "admin/putBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/bank/changeStatus/${payload?.userid}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);



// Bank Get api

export const getBankDetailsByIdAsync = createAsyncThunk(
  "admin/getBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/bank/${payload?.userid}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// get user details by id api
export const getNavAdminIdAsync = createAsyncThunk(
  "admin/navAdminIcon",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/me/${payload?.staffId}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Department get api
export const getDepartmentAsync = createAsyncThunk(
  "admin/getAlldepartment",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllDepartment?department=${payload.department || ""}`,

      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// department post api

export const DepartmentpostAsync = createAsyncThunk(
  "admin/adddepartment",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addDepartment`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Department put api
export const putDepartmentAsync = createAsyncThunk(
  "admin/editdepartment",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateDepartment/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Department Delete api
export const deleteDepartmentByIdAsync = createAsyncThunk(
  "admin/department/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/department/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Department getbyid api
export const getDepartmentByIdAsync = createAsyncThunk(
  "admin/getbyiddepartment/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getDepartmentById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // asset getType api
export const getAssetTypeAsync = createAsyncThunk(
  "admin/getAllassetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllAssetType`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // asset get api
export const getAssetAsync = createAsyncThunk(
  "admin/getAllasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllAsset`,

      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Asset post api

export const postAssetAsync = createAsyncThunk(
  "admin/postasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAsset`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // asset put api
export const putAssetAsync = createAsyncThunk(
  "admin/editasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateAsset/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// asset Delete api
export const deleteAssetByIdAsync = createAsyncThunk(
  "admin/deleteasset/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",

      `api/v1/deleteAsset/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // asset getbyid api
export const getAssetByIdAsync = createAsyncThunk(
  "admin/getbyidasset/",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",

      `api/v1/getAssetById/${payload.id}`,

      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // task getType api
export const getTaskUserAsync = createAsyncThunk(
  "admin/getAllTaskUser",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/staff/list-staff`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // task get api
export const getTaskAsync = createAsyncThunk(
  "admin/getAllTask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllTask?projectId=${payload?.projectId || ""}&service=${
        payload?.service || ""
      }&staffId=${payload?.staffId || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // task post api

export const postTaskAsync = createAsyncThunk(
  "admin/poststask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addTask`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // task put api
export const putTaskAsync = createAsyncThunk(
  "admin/editstask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateTask/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// task Delete api
export const deleteTaskByIdAsync = createAsyncThunk(
  "admin/deletetask/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteTask/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // task getbyid api
export const getTaskByIdAsync = createAsyncThunk(
  "admin/getbyidTask/",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getTaskById/${payload?.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const putTaskStatusAsync = createAsyncThunk(
  "admin/putAllTaskStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateTaskByStaff/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// get leave by id
export const getAllLeaveListAsync = createAsyncThunk(
  "admin/users",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getLeave/${payload.staffId || ""}?page=${
        payload?.page || ""
      }&limit=${payload.limit || ""}&startDate=${
        payload?.startDate || ""
      }&endDate=${payload?.endDate || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
// apply leave by id api
export const applyleaveAsync = createAsyncThunk(
  "admin/applyleave/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/applyLeave`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
// get all leave
export const getAllLeaveAsync = createAsyncThunk(
  "admin/leaves",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllLeave?page=${payload?.page || ""}&limit=${
        payload.limit || ""
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&name=${payload?.name || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putleaveAsync = createAsyncThunk(
  "admin/users",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateLeave/${payload?.id || ''}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Expense Add api
export const addExpensesAsync = createAsyncThunk(
  "admin/addExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/expenses/create-expense`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
// Expense get api
export const getExpensesAsync = createAsyncThunk(
  "admin/getExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/expenses/list-expenses?projectId=${
        payload?.projectId || ""
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&search=${payload?.search || ""}&page=${""}&limit=${""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Expense getById api
export const getExpenseByIdAsync = createAsyncThunk(
  "admin/getExpenseById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/expenses/get-expenses/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Expense put api
export const putExpensesAsync = createAsyncThunk(
  "admin/putExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/expenses/update-expenses/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putMultipleStatusAsync = createAsyncThunk(
  "admin/putMultipleStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/expenses/updateMultipleStatus`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Expense Delete page
export const deleteExpensesAsync = createAsyncThunk(
  "admin/delExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/expenses/delete-expenses/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//subdescription

export const getSubDescriptionAsync = createAsyncThunk(
  "admin/get-allsubdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/subdescription/get-allsubdescription`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getSubDescriptionByIdAsync = createAsyncThunk(
  "admin/get-subdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/subdescription/get-subdescription/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addSubDescriptionAsync = createAsyncThunk(
  "admin/create-subdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/subdescription/create-subdescription`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getSubDescriptionByDescriptionIdAsync = createAsyncThunk(
  "admin/create-subdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/subdescription/get-allsubdescription?descriptionId=${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putSubDescriptionAsync = createAsyncThunk(
  "admin/update-subdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/subdescription/update-subdescription/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteSingleSubDescriptionAsync = createAsyncThunk(
  "admin/delete-subdescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/subdescription/delete-subdescription/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//description

export const getDescriptionAsync = createAsyncThunk(
  "admin/getAllDescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/description/getAllDescription`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getDescriptionByIdAsync = createAsyncThunk(
  "admin/getSingleDescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/description/getSingleDescription/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addDescriptionAsync = createAsyncThunk(
  "admin/createDescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/description/createDescription`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putDescriptionAsync = createAsyncThunk(
  "admin/updateDescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/description/updateDescription/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteSingleDescriptionAsync = createAsyncThunk(
  "admin/deleteDescription",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/description/deleteDescription/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//GET Roles for SignUp
export const getRolesforSignUpAsync = createAsyncThunk(
  "admin/getRolesforSignUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllRolesForSignup`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//GET Roles
export const getRolesAsync = createAsyncThunk(
  "admin/getAllRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllRoles`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//ADD ROLES
export const addRolesAsync = createAsyncThunk(
  "admin/addRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/createRole`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//getBy Id Roles
export const getRolesByIdAsync = createAsyncThunk(
  "admin/getRolesById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getSingleRole/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//put roles
export const putRolesAsync = createAsyncThunk(
  "admin/putRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateRole/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//delete roles
export const deleteSingleRoleAsync = createAsyncThunk(
  "admin/deleteRole",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteRole/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Payroll
export const getPayrollAsync = createAsyncThunk(
  "admin/getPayroll",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getMonthlyPaymentDetailsOfStaff?month=${payload.month}&year=${payload.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// payroll monthly salary
export const getPayrollSalaryAsync = createAsyncThunk(
  "admin/getPayrollSalary",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getMonthlyPaymentDetails?month=${payload?.month}&year=${payload?.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//PuT Payroll
export const putPayrollAsync = createAsyncThunk(
  "admin/putRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/payMonthlyPaymentToStaff/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// AssetType

// GET
export const getAllAssetTypeAsync = createAsyncThunk(
  "admin/getAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllAssetType`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//PUT
export const putAssetTypeAsync = createAsyncThunk(
  "admin/putAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateAssetType/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Add Asset Type (Post)

export const addAssetTypeAsync = createAsyncThunk(
  "admin/addAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAssetType`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get by id

export const getAssetTypeByIdAsync = createAsyncThunk(
  "admin/getAssetTypeById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAssetTypeById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Delete

export const deleteAssetTypeAsync = createAsyncThunk(
  "admin/deleteAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteAssetType/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Assign Asset section start -----------
//getAssignassetList (Get)
export const getAssignAssetAsync = createAsyncThunk(
  "admin/getAssignAssetAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllAssignAsset?staffId=${payload?.staffId || ""}&projectId=${
        payload?.projectId || ""
      }`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//AddassignAsset (Post)
export const addAssignAssetAsync = createAsyncThunk(
  "admin/addAssignAssetAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAssignAsset`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Delete Asign Asset 
export const deleteAssignedAssetAsync = createAsyncThunk(
  "admin/deleteAssignedAsset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteAssignAsset/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Get by id
export const getAssignAssetByIdAsync = createAsyncThunk(
  "admin/getAssignAssetById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAssignAssetById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//put
export const updateAssignAssetAsync = createAsyncThunk(
  "admin/updateAssignAssetAsync/staff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/returnAssignAsset/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//getAll Staff-----
export const getAllStaffAsync = createAsyncThunk(
  "admin/getAllStaffAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/staff/list-staff`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
// Assign Asset section  End -----------
export const getAllHolidayAsync = createAsyncThunk(
  "admin/getAllHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllHolidays?year=${payload.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getSingleHolidayAsync = createAsyncThunk(
  "admin/getSingleHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleHoliday/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addHolidayAsync = createAsyncThunk(
  "admin/addHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addHoliday`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const deleteHolidayByIdAsync = createAsyncThunk(
  "admin/deleteHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleHoliday/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putHolidayAsync = createAsyncThunk(
  "admin/putHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateHoliday/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// create Previous Attendance
export const postPreviousAttendanceAsync = createAsyncThunk(
  "admin/postpreviousattendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/createAttendenceManually`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// ============================== Customer Section ==============================
//Get All Customer--------------
export const getAllCustomerListAsync = createAsyncThunk(
  "admin/getAllCustomerList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllCustomer`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Single Customer by Id--------------
export const getCustomerByIdAsync = createAsyncThunk(
  "admin/getCustomerById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getCustomerById/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Post or Add Customer--------------------
export const addCustomerAsync = createAsyncThunk(
  "admin/addCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addCustomer`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Put or Update Customer------------------
export const putCustomerAsync = createAsyncThunk(
  "admin/putCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateCustomer/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Customer---------------------------
export const deleteCustomerAsync = createAsyncThunk(
  "admin/deleteCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteCustomer/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//change password---
export const putChangePaswordAsync = createAsyncThunk(
  "admin/putChangePaswordAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/staff/password/update`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// All company
export const getAllCompanyAsync = createAsyncThunk(
  "admin/getAllCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllProjects?search=${payload.companyName || ""}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getCompanyByIdAsync = createAsyncThunk(
  "admin/getCompanyById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleProject/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putCompanyByAsync = createAsyncThunk(
  "admin/putCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateProject/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteCompanyByIdAsync = createAsyncThunk(
  "admin/deletecompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleProject/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addCompanyAsync = createAsyncThunk(
  "admin/addCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addProject`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
//invoice module start--------
export const addInvoiceAsync = createAsyncThunk(
  "admin/addInvoice",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addInvoice`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getAllInvoicesAsync = createAsyncThunk(
  "admin/getAllInvoices",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllInvoice`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updateInvoiceAsync = createAsyncThunk(
  "admin/updateInvoiceAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateInvoice/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const deleteInvoiceAsync = createAsyncThunk(
  "admin/deleteInvoiceAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteInvoice/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getInvoiceByIdAsync = createAsyncThunk(
  "admin/getInvoiceByIdAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleInvoice/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getInvoicesStatusDetailsAsync = createAsyncThunk(
  "admin/getInvoicesStatusDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getInvoicesStatusDetails`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getCurrentInvoiceNumberAsync = createAsyncThunk(
  "admin/getCurrentInvoiceNumberAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getCurrentInvoiceNumber`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updateInvoiceStatusAsync = createAsyncThunk(
  "admin/updateInvoiceStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateInvoiceStatus/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//invoice module  End--------

//get services
export const getAllServicesAsync = createAsyncThunk(
  "admin/getAllServices",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllServices`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//GetByID Service
export const getServiceByIdAsync = createAsyncThunk(
  "admin/getServiceById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleService/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Addservice
export const postServiceAsync = createAsyncThunk(
  "admin/postService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addService`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Update service
export const putServiceAsync = createAsyncThunk(
  "admin/putService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateService/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete service
export const deleteServiceAsync = createAsyncThunk(
  "admin/deleteService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleService/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Get Date Of Birth Api
export const getUserDOBAsync = createAsyncThunk(
  "admin/getUserDOB",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/getUsersBasedOnDob?dobDate=${payload?.dobDate || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getUserAnniversaryAsync = createAsyncThunk(
  "admin/getUserAnniversary",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staff/getUsersBasedOnJoiningDate?joiningDate=${
        payload?.joiningDate || ""
      }`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//permission module start---->>>>
export const getAllPermissionAsync = createAsyncThunk(
  "admin/getAllPermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllPermissions`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getSinglePermissionAsync = createAsyncThunk(
  "admin/getSinglePermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSinglePermission/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getAllRoutesAsync = createAsyncThunk(
  "admin/getAllRoutesAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllRoutes`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const createPermissionAsync = createAsyncThunk(
  "admin/createPermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/createPermission`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getPermissionBasedOnRoleAsync = createAsyncThunk(
  "admin/getPermissionBasedOnRole",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getPermissionBasedOnRole`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//permission module  end---->>>>>>>

//Leave Balance

export const getLeaveBalanceAsync = createAsyncThunk(
  "admin/getLeaveBalance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAlllogs?staffId=${payload?.staffId}&month=${payload?.month}&year=${payload?.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Bankdetail get api
export const getBankdetailAsync = createAsyncThunk(
  "admin/getAllBankdetail",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/bank`,[],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// =============================== Advance =====================================================
export const getAllAdvanceListAsync = createAsyncThunk(
  "admin/getAllAdvanceList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/listAdvance?type=${payload.type || ''}&projectId=${payload.projectId || ''}&staffId=${payload.staffId || ''}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Advance Data by Id--------------
export const getAdvanceDataByIdAsync = createAsyncThunk(
  "admin/getAdvanceDataById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAdvance/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Post or Add Advance--------------------
export const addAdvanceAsync = createAsyncThunk(
  "admin/addAdvance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/createAdvance`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Put or Update Advance------------------
export const putAdvanceDataAsync = createAsyncThunk(
  "admin/putAdvanceData",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateAdvance/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Advance---------------------------
export const deleteAdvanceAsync = createAsyncThunk(
  "admin/deleteAdvance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteAdvance/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Get All Staff For Advance
export const getAllStaffForAdvanceAsync = createAsyncThunk(
  "admin/getAllStaffForAdvance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getStaff?projectId=${payload?.projectId || []}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
export const getAllStaffByProjectIdAsync = createAsyncThunk(
  "admin/getAllStaffByProjectId",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getStaff?projectId=${payload?.projectId || []}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// =============================== UAN Ans ESI =====================================================
export const getAllUanAndEsiListAsync = createAsyncThunk(
  "admin/getAllUanAndEsiList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/listUanAndEsi`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Advance Data by Id--------------
export const getUanAndEsiByIdAsync = createAsyncThunk(
  "admin/getUanAndEsiById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getUanAndEsi/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Post or Add Advance--------------------
export const addUanAndEsiAsync = createAsyncThunk(
  "admin/addUanAndEsi",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addUanAndEsi`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Put or Update Advance------------------
export const putUanAndEsiAsync = createAsyncThunk(
  "admin/putUanAndEsi",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateUanAndEsi/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Advance---------------------------
export const deleteUanAndEsiAsync = createAsyncThunk(
  "admin/deleteUanAndEsi",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteUanAndEsi/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);


