import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllCustomerListAsync,
  getCustomerByIdAsync,
  addCustomerAsync,
  putCustomerAsync,
  deleteCustomerAsync,
} from "../async.api";

const initialState = {
  customerLoader: false,
  getAllCustomerList: [],
  getCustomerById: [],
  addCustomer: [],
  putCustomer: [],
  deleteCustomer: [],
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllCustomerListAsync.pending,
        getCustomerByIdAsync.pending,
        addCustomerAsync.pending,
        putCustomerAsync.pending,
        deleteCustomerAsync.pending
      ),
      (state) => {
        state.customerLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCustomerListAsync.fulfilled),
      (state, action) => {
        state.customerLoader = false;
        state.getAllCustomerList = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCustomerByIdAsync.fulfilled),
      (state, action) => {
        state.customerLoader = false;
        state.getCustomerById = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(addCustomerAsync.fulfilled), (state, action) => {
      state.customerLoader = false;
      state.addCustomer = action.payload;
    });
    builder.addMatcher(isAnyOf(putCustomerAsync.fulfilled), (state, action) => {
      state.customerLoader = false;
      state.putCustomer = action.payload;
    });
    builder.addMatcher(
      isAnyOf(deleteCustomerAsync.fulfilled),
      (state, action) => {
        state.customerLoader = false;
        state.deleteCustomer = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllCustomerListAsync.rejected,
        getCustomerByIdAsync.rejected,
        addCustomerAsync.rejected,
        putCustomerAsync.rejected,
        deleteCustomerAsync.rejected
      ),
      (state) => {
        state.customerLoader = true;
      }
    );
  },

  reducers: {
    emptyCustomer: () => initialState,
  },
});

export const { emptyCustomer } = customerSlice.actions;

export default customerSlice.reducer;
