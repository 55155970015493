import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { postSignUpAsync } from "../async.api";

const initialState = {
  loader: false,
  signup: [],
};

export const signUpSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(postSignUpAsync.pending), (state) => {
      state.loader = true;
    });

    builder.addMatcher(isAnyOf(postSignUpAsync.fulfilled), (state, action) => {
      state.loader = false;
      state.signup = action.payload;
    });

    builder.addMatcher(isAnyOf(postSignUpAsync.rejected), (state) => {
      state.loader = false;
    });
  },

  reducers: {
    emptySignUp: () => initialState,
  },
});

export const { emptySignUp } = signUpSlice.actions;

export default signUpSlice.reducer;
