import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAllPermissionAsync,getAllRoutesAsync,getSinglePermissionAsync ,createPermissionAsync,
  getPermissionBasedOnRoleAsync} from "../async.api";

const initialState = {
  permissionLoader: false,
  allPermissionList: [],
  getAllRoutes:[],
  permissionError: [],
  permissionById:[],
  addPermissions:[],
  getPermissionBasedOnRole:[]
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllPermissionAsync.pending,createPermissionAsync.pending), (state) => {
      state.permissionLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllRoutesAsync.fulfilled),
      (state, action) => {
        state.permissionLoader = false;
        state.getAllRoutes = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getSinglePermissionAsync.fulfilled),
      (state, action) => {
        state.permissionLoader = false;
        state.permissionById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getPermissionBasedOnRoleAsync.fulfilled),
      (state, action) => {
        state.permissionLoader = false;
        state.getPermissionBasedOnRole = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createPermissionAsync.fulfilled),
      (state, action) => {
        state.permissionLoader = false;
        state.addPermissions = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPermissionAsync.rejected),
      (state, action) => {
        state.permissionLoader = false;
        state.permissionError = action.payload;
      }
    );
  },
  reducers: {
    emptyPermission: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
