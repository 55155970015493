import { Navigate, useRoutes } from "react-router-dom";
// auth
import GuestGuard from "../auth/GuestGuard";
// layouts
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
import NavigateMiddleware from "../layouts/Middleware/NavigateMiddleware";
import RoutePermissionMiddleware from "../layouts/Middleware/RoutePermissionMiddleware";
// config
import { PATH_AFTER_LOGIN } from "../config";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralMonthlyPage,
  GeneralCompanypage,
  GeneralDepartmanPage,
  GeneralBookingPage,
  GeneralAnalyticsPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  DailyAttendance,
  DepartmanEditPage,
  DepartmanCreatePage,
  UserProfilePage,
  UserAccountPage,
  CompanyCreatePage,
  CompanyEditPage,
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  FileManagerPage,
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  BlankPage,
  PermissionDeniedPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  Staff,
  StaffBankDetails,
  Roles,
  NewRoles,
  Permission,
  LeaveManagment,
  InventoryManagement,
  AddAsset,
  AssignAsset,
  AssetCreate,
  TaskManagement,
  TaskForm,
  AddTask,
  ChangeStatus,
  ApplyLeave,
  AllLeave,
  Department,
  DepartmentCreate,
  DepartmentEdit,
  DepartmentAdd,
  ExpensesPage,
  CreateExpense,
  EditExpense,
  AttendancePage,
  Payroll,
  Password,
  Services,
  Holiday,
  PermissionPage,
  StaffLeaves,
  LeaveBalance,
  DescriptionListPage,
  SubDescriptionListPage,
  ProjectDetailPage,
  ProjectCreate,
  ProjectEdit,
  GeneralEventPage,
  Customer,
  EditCustomer,
  CreateNewCustomer,
  BankDetailPage,
  AddStaffBankDetail,
  Advance,
  UanAndEsiPage,
} from "./elements";
import AuthMiddleware from "../layouts/Middleware/AuthMiddleware";
import AssetType from "src/pages/dashboard/AssetType";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <LoginPage /> },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    //Dashboard
    {
      path: "dashboard",
      element: (
        <AuthMiddleware>
          <NavigateMiddleware />
          <RoutePermissionMiddleware>
            <DashboardLayout />
          </RoutePermissionMiddleware>
          <NavigateMiddleware />
        </AuthMiddleware>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralAppPage /> },
        { path: "event", element: <GeneralEventPage /> },
        { path: "analytics", element: <GeneralAnalyticsPage /> },
        { path: "banking", element: <GeneralBankingPage /> },
        { path: "booking", element: <GeneralBookingPage /> },
        { path: "file", element: <GeneralFilePage /> },
        { path: "company", element: <GeneralCompanypage /> },
        { path: "monthly", element: <GeneralMonthlyPage /> },
        { path: "department", element: <GeneralDepartmanPage /> },

        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShopPage /> },
            { path: "product/:name", element: <EcommerceProductDetailsPage /> },
            { path: "list", element: <EcommerceProductListPage /> },
            { path: "product/new", element: <EcommerceProductCreatePage /> },
            {
              path: "product/:name/edit",
              element: <EcommerceProductEditPage />,
            },
            { path: "checkout", element: <EcommerceCheckoutPage /> },
          ],
        },

        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfilePage /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":name/edit", element: <UserEditPage /> },
            { path: "account", element: <UserAccountPage /> },
            { path: "edit", element: <DepartmanEditPage /> },
            { path: "depart", element: <DepartmanCreatePage /> },
          ],
        },

        {
          path: "staff",
          children: [
            {
              element: <Navigate to="/dashboard/staff" replace />,
              index: true,
            },
            { path: "list", element: <Staff /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":name/edit", element: <UserEditPage /> },
            { path: ":id/bank", element: <StaffBankDetails /> },
            { path: ":id/password", element: <Password /> },
            { path: "account", element: <UserAccountPage /> },
            { path: ":id/view", element: <StaffLeaves /> },
            { path: ":id/leavebalance", element: <LeaveBalance /> },
            { path: "dailyAttendance", element: <DailyAttendance /> },
          ],
        },

        {
          path: "bankdetail",
          children: [
            {
              element: <Navigate to="/dashboard/bankdetail/list" replace />,
              index: true,
            },
            { path: "list", element: <BankDetailPage /> },
            { path: ":id/add", element: <AddStaffBankDetail /> },
          ],
        },

        {
          path: "customer",
          children: [
            {
              element: <Navigate to="/dashboard/customer" replace />,
              index: true,
            },
            { path: "list", element: <Customer /> },
            { path: "new", element: <CreateNewCustomer /> },
            { path: ":id/edit", element: <EditCustomer /> },
          ],
        },

        {
          path: "roles",
          children: [
            {
              element: <Navigate to="/dashboard/roles/table" replace />,
              index: true,
            },
            { path: "table", element: <Roles /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "new", element: <NewRoles /> },
            { path: "permission/:id", element: <PermissionPage /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },

        {
          path: "leavemanagment",
          children: [
            {
              element: (
                <Navigate to="/dashboard/leavemanagment/table" replace />
              ),
              index: true,
            },
            { path: "table", element: <LeaveManagment /> },
            { path: "applyleave", element: <ApplyLeave /> },
            { path: "allleave", element: <AllLeave /> },
            { path: "new", element: <NewRoles /> },
            { path: "permission/:id", element: <Permission /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },

        {
          path: "InventoryManagement",
          children: [
            {
              element: (
                <Navigate to="/dashboard/InventoryManagement/table" replace />
              ),
              index: true,
            },
            { path: "table", element: <InventoryManagement /> },
            { path: "assignAsset", element: <AssignAsset /> },
            { path: "addasset", element: <AddAsset /> },
            { path: "create", element: <AssetCreate /> },
            { path: "assettype", element: <AssetType /> },
          ],
        },

        {
          path: "TaskManagement",
          children: [
            {
              element: (
                <Navigate to="/dashboard/TaskManagement/table" replace />
              ),
              index: true,
            },
            { path: "table", element: <TaskManagement /> },
            { path: "addtask", element: <AddTask /> },
            { path: "TaskForm", element: <TaskForm /> },
            { path: "ChangeStatus", element: <ChangeStatus /> },
          ],
        },

        {
          path: "generalsetting",
          children: [
            {
              element: <Navigate to="/dashboard/generalsetting" replace />,
              index: true,
            },
            { path: "holiday", element: <Holiday /> },
          ],
        },
        {
          path: "department",
          children: [
            {
              element: <Navigate to="/dashboard/department/table" replace />,
              index: true,
            },
            { path: "table", element: <Department /> },
            { path: "create", element: <DepartmentCreate /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":id/edit", element: <DepartmentEdit /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },

        {
          path: "expense",
          children: [
            {
              element: <Navigate to="/dashboard/expense/table" replace />,
              index: true,
            },
            { path: "table", element: <ExpensesPage /> },
            { path: "add", element: <CreateExpense /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":id/edit", element: <EditExpense /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },
        {
          path: "payroll",
          children: [
            {
              element: <Navigate to="/dashboard/payroll/table" replace />,
              index: true,
            },
            { path: "table", element: <Payroll /> },
          ],
        },
        {
          path: "advance",
          children: [
            {
              element: <Navigate to="/dashboard/advance/table" replace />,
              index: true,
            },
            { path: "table", element: <Advance/> },
          ],
        },
        {
          path: "uanandesi",
          children: [
            {
              element: <Navigate to="/dashboard/uanandesi/list" replace />,
              index: true,
            },
            { path: "list", element: <UanAndEsiPage/> },
          ],
        },
        {
          path: "attendance",
          children: [
            {
              element: <Navigate to="/dashboard/attendance/list" replace />,
              index: true,
            },
            { path: "list", element: <AttendancePage /> },
          ],
        },

        {
          path: "department",
          children: [
            {
              element: <Navigate to="/dashboard/department/table" replace />,
              index: true,
            },
            { path: "table", element: <Department /> },
            { path: "add", element: <DepartmentAdd /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":id/edit", element: <StaffBankDetails /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },
        {
          path: "roles",
          children: [
            {
              element: <Navigate to="/dashboard/roles/table" replace />,
              index: true,
            },
            { path: "cards", element: <UserCardsPage /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":id/edit", element: <StaffBankDetails /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },

        {
          path: "master",
          children: [
            {
              element: <Navigate to="/dashboard/master" replace />,
              index: true,
            },
            { path: "department", element: <Department /> },
            { path: "roles", element: <Roles /> },
            { path: "services", element: <Services /> },
            { path: "description", element: <DescriptionListPage /> },
            { path: "subdescription", element: <SubDescriptionListPage /> },
          ],
        },
        {
          path: "project",
          children: [
            {
              element: <Navigate to="/dashboard/project" replace />,
              index: true,
            },
            { path: "list", element: <ProjectDetailPage /> },
            { path: "create", element: <ProjectCreate /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":id/edit", element: <ProjectEdit /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },

        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceListPage /> },
            { path: ":id", element: <InvoiceDetailsPage /> },
            { path: ":id/edit", element: <InvoiceEditPage /> },
            { path: ":id/view", element: <InvoiceDetailsPage /> },
            { path: "new", element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPostsPage /> },
            { path: "post/:title", element: <BlogPostPage /> },
            { path: "new", element: <BlogNewPostPage /> },
          ],
        },
        { path: "files-manager", element: <FileManagerPage /> },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <MailPage /> },
            { path: "label/:customLabel/:mailId", element: <MailPage /> },
            { path: ":systemLabel", element: <MailPage /> },
            { path: ":systemLabel/:mailId", element: <MailPage /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <ChatPage />, index: true },
            { path: "new", element: <ChatPage /> },
            { path: ":conversationKey", element: <ChatPage /> },
          ],
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "kanban", element: <KanbanPage /> },
        { path: "permission-denied", element: <PermissionDeniedPage /> },
        { path: "blank", element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <AboutPage /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <FaqsPage /> },
        // Demo Components
        {
          path: "components",
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: "foundation",
              children: [
                {
                  element: (
                    <Navigate to="/components/foundation/colors" replace />
                  ),
                  index: true,
                },
                { path: "colors", element: <FoundationColorsPage /> },
                { path: "typography", element: <FoundationTypographyPage /> },
                { path: "shadows", element: <FoundationShadowsPage /> },
                { path: "grid", element: <FoundationGridPage /> },
                { path: "icons", element: <FoundationIconsPage /> },
              ],
            },
            {
              path: "mui",
              children: [
                {
                  element: <Navigate to="/components/mui/accordion" replace />,
                  index: true,
                },
                { path: "accordion", element: <MUIAccordionPage /> },
                { path: "alert", element: <MUIAlertPage /> },
                { path: "autocomplete", element: <MUIAutocompletePage /> },
                { path: "avatar", element: <MUIAvatarPage /> },
                { path: "badge", element: <MUIBadgePage /> },
                { path: "breadcrumbs", element: <MUIBreadcrumbsPage /> },
                { path: "buttons", element: <MUIButtonsPage /> },
                { path: "checkbox", element: <MUICheckboxPage /> },
                { path: "chip", element: <MUIChipPage /> },
                { path: "data-grid", element: <MUIDataGridPage /> },
                { path: "dialog", element: <MUIDialogPage /> },
                { path: "list", element: <MUIListPage /> },
                { path: "menu", element: <MUIMenuPage /> },
                { path: "pagination", element: <MUIPaginationPage /> },
                { path: "pickers", element: <MUIPickersPage /> },
                { path: "popover", element: <MUIPopoverPage /> },
                { path: "progress", element: <MUIProgressPage /> },
                { path: "radio-button", element: <MUIRadioButtonsPage /> },
                { path: "rating", element: <MUIRatingPage /> },
                { path: "slider", element: <MUISliderPage /> },
                { path: "stepper", element: <MUIStepperPage /> },
                { path: "switch", element: <MUISwitchPage /> },
                { path: "table", element: <MUITablePage /> },
                { path: "tabs", element: <MUITabsPage /> },
                { path: "textfield", element: <MUITextFieldPage /> },
                { path: "timeline", element: <MUITimelinePage /> },
                { path: "tooltip", element: <MUITooltipPage /> },
                { path: "transfer-list", element: <MUITransferListPage /> },
                { path: "tree-view", element: <MUITreesViewPage /> },
              ],
            },
            {
              path: "extra",
              children: [
                {
                  element: <Navigate to="/components/extra/animate" replace />,
                  index: true,
                },
                { path: "animate", element: <DemoAnimatePage /> },
                { path: "carousel", element: <DemoCarouselsPage /> },
                { path: "chart", element: <DemoChartsPage /> },
                {
                  path: "copy-to-clipboard",
                  element: <DemoCopyToClipboardPage />,
                },
                { path: "editor", element: <DemoEditorPage /> },
                {
                  path: "form-validation",
                  element: <DemoFormValidationPage />,
                },
                { path: "image", element: <DemoImagePage /> },
                { path: "label", element: <DemoLabelPage /> },
                { path: "lightbox", element: <DemoLightboxPage /> },
                { path: "map", element: <DemoMapPage /> },
                { path: "mega-menu", element: <DemoMegaMenuPage /> },
                { path: "multi-language", element: <DemoMultiLanguagePage /> },
                { path: "navigation-bar", element: <DemoNavigationBarPage /> },
                {
                  path: "organization-chart",
                  element: <DemoOrganizationalChartPage />,
                },
                { path: "scroll", element: <DemoScrollbarPage /> },
                { path: "snackbar", element: <DemoSnackbarPage /> },
                { path: "text-max-line", element: <DemoTextMaxLinePage /> },
                { path: "upload", element: <DemoUploadPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "pricing", element: <PricingPage /> },
        { path: "payment", element: <PaymentPage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
