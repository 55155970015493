import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getDescriptionAsync,
  getDescriptionByIdAsync,
  addDescriptionAsync,
  putDescriptionAsync,
  deleteSingleDescriptionAsync,
} from "../async.api";

const initialState = {
  descriptionLoader: false,
  getDescriptionList: [],
  addDescription: [],
  putDescription: [],
  getDescriptionById: [],
  deleteDescription: [],
};

export const descriptionSlice = createSlice({
  name: "rolesSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getDescriptionAsync.pending,
        addDescriptionAsync.pending,
        putDescriptionAsync.pending,
        getDescriptionByIdAsync.pending,
        deleteSingleDescriptionAsync.pending
      ),
      (state) => {
        state.descriptionLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getDescriptionAsync.fulfilled),
      (state, action) => {
        state.descriptionLoader = false;
        state.getDescriptionList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(addDescriptionAsync.fulfilled),
      (state, action) => {
        state.descriptionLoader = false;
        console.log("addRoleSlice", action.payload);
        state.addDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putDescriptionAsync.fulfilled),
      (state, action) => {
        state.descriptionLoader = false;
        state.putDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteSingleDescriptionAsync.fulfilled),
      (state, action) => {
        state.descriptionLoader = false;
        state.deleteDescription = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getDescriptionByIdAsync.fulfilled),
      (state, action) => {
        state.descriptionLoader = false;
        state.getDescriptionById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getDescriptionAsync.rejected,
        addDescriptionAsync.rejected,
        putDescriptionAsync.rejected,
        getDescriptionByIdAsync.rejected,
        deleteSingleDescriptionAsync.rejected
      ),
      (state) => {
        state.descriptionLoader = false;
      }
    );
  },

  reducers: {
    emptyDescription: () => initialState,
  },
});

export const { emptyDescription } = descriptionSlice.actions;

export default descriptionSlice.reducer;
