// routes
import Router from "./routes";
import { Toaster } from "react-hot-toast";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { StyledChart } from "./components/chart";
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import { getAuth } from "../src/pages/dashboard/Auth/getAuth";
import { setUserInfoRedux } from "../src/redux/slices/userInfo.slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// import funcOneSignal from "./oneSignal";
// ----------------------------------------------------------------------

export default function App() {
  const auth = getAuth();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();

  const token = localStorage.getItem('token');

  //onesignal
  // useEffect(() => {
  //   funcOneSignal()
  // }, [])

  useEffect(() => {
    if (auth) {
      dispatch(setUserInfoRedux(auth));
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (location.pathname === '/dashboard' || location.pathname === '/') {
      navigate('/dashboard/app');
    }
    // eslint-disable-next-line
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (!token) {
      navigate('/auth/login');
    }
    // eslint-disable-next-line
  }, [token])

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
          <ThemeLocalization>
            <SnackbarProvider>
              <StyledChart />
              <Router />
              <Toaster reverseOrder={false} />
            </SnackbarProvider>
          </ThemeLocalization>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
