import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getNavAdminIdAsync } from "../async.api";

const initialState = {
  userLoader: false,
  userData: [],
  userErrorLoader: [],
};

export const userDetailsSlice = createSlice({
  name: "userdetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getNavAdminIdAsync.pending), (state) => {
      state.userLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getNavAdminIdAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        console.log(action.payload, " action.payload");
        state.userData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getNavAdminIdAsync.rejected),
      (state, action) => {
        state.userLoader = false;
        state.userErrorLoader = action.payload;
      }
    );
  },
  reducers: {
    emptyuserInfo: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyuserInfo } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
